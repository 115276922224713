<template>
  <footer class="footer">
    <div class="footer-icons">
      <!-- 홈 -->
      <div class="footer-item" @click="navigateTo('/home')">
        <v-icon class="footer-icon">
          {{ $route.name === 'Home' ? 'mdi-home' : 'mdi-home-outline' }}
        </v-icon>
        <span class="footer-label">홈</span>
      </div>

      <!-- 공유하기 -->
      <div class="footer-item" @click="navigateTo('/community')">
        <v-icon class="footer-icon">
          {{ $route.name === 'Community' ? 'mdi-chat' : 'mdi-chat-outline' }}
        </v-icon>
        <span class="footer-label">공유하기</span>
      </div>

      <!-- 라이브 -->
      <div class="footer-item" @click="navigateTo('/saved')">
        <v-icon class="footer-icon">
          {{ $route.name === 'Saved' ? 'mdi-bookmark' : 'mdi-bookmark-outline' }}
        </v-icon>
        <span class="footer-label">라이브</span>
      </div>

      <!-- 프로필 -->
      <div class="footer-item" @click="navigateTo('/profile')">
        <!--
        <v-icon class="footer-icon">
          {{ $route.name === 'Profile' ? 'mdi-account-circle' : 'mdi-account-circle-outline' }}
        </v-icon>
        -->
        <img class="footer-icon" src="/assets/logo-symbol.png" alt="avatar" />
        <span class="footer-label">프로필</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  background-color: #fff;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.footer-icons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 500px;
  padding: 0 15px;
}

.footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.footer-icon {
  height: 28px;
  color: #000;
}

.footer-label {
  font-size: 11px;
  color: #333;
  margin-top: 2px;
}
</style>
